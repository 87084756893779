<template>
  <div>
    <div class="site-container">
      <banner
        variant="purple"
        btn-text="Seja um corretor Divvy!"
        :image="require('../assets/images/banner-corretor.png')"
        title="Venda Cotas Imobiliárias e ganhe comissões rápidas e fáceis!"
        @btnClick="() => $router.push({ name: 'register-profile' })" />
    </div>

    <div class="site-container">
      <div class="site-content">
        <step-banner class="my-4" />
      </div>
    </div>

    <!--div class="banner-purple" :style="{ backgroundImage: `url(${require('../assets/images/bg-purple-banner.png')})` }">
      <div class="site-container">
        <h1 class="text-center text-white bold py-5">
          Ainda não entendeu?<br />
          Explicamos para você!
        </h1>
        <div class="site-content">
          <div style="height: 40vw; max-height: 500px">
            <youtube />
          </div>

          <b-row class="mt-4">
            <b-col cols="2">
              <link-seta />
            </b-col>
            <b-col cols="10">
              <parceiros-contrutoras style="width: 100%" />
            </b-col>
          </b-row>
        </div>
      </div>
    </div-->

    <div class="site-container">
      <div style="height: 40vw; max-height: 500px">
        <youtube color="#b478e5" src="https://www.youtube.com/embed/e_QGRaCBrtc" />
      </div>
    </div>

    <div class="site-container mt-5">
      <h1 class="text-center bold py-3">Como funciona?</h1>
      <action-flux-corretor />
    </div>

    <div :style="{ backgroundImage: `url(${require('../assets/images/bg-vector-purple.png')})`, backgroundSize: `cover`, backgroundRepeat: `no-repeat`, backgroundPosition: `center` }">
      <div class="site-container" style="padding-top: 180px">
        <div class="site-content">
          <h1 style="font-weight: 700">Benefícios de ser um Corretor Divvy</h1>
          <b-row class="mt-5">
            <b-col>
              <card-border-icon icon="layout-text-sidebar-reverse" color="#BC7DF0" text="Portfólio diversificado de imóveis na planta de médio e alto padrão." />
              <card-border-icon icon="bar-chart" color="#BC7DF0" text="Aumento expressivo do público de clientes para oferecer cotas imobiliárias." />
              <card-border-icon icon="cash-stack" color="#BC7DF0" text="Cotas imobiliárias a partir de R$ 4.000,00." />
            </b-col>
            <b-col>
              <card-border-icon
                icon="people-fill"
                color="#BC7DF0"
                text="Acesso para seus clientes em nossa plataforma para acompanhamento da evolução da obra, análises de investimento, projeções de retorno e valorização das cotas." />
              <card-border-icon icon="check" color="#BC7DF0" text="Acesso a plataforma online para acompanhamento das suas vendas, comissões, análises de investimento e imóveis. " />
              <card-border-icon icon="wifi" color="#BC7DF0" text="Todo o processo feito de maneira 100% online, simples, rápido e fácil para você corretor e seus clientes." />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <div class="site-container" style="padding-top: 100px">
      <div
        class="component-container p-5"
        style="border-radius: 12px"
        :style="{ backgroundSize: 'cover', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat', backgroundImage: `url(${require('../assets/images/bg-vector-rounded-purple.png')})` }">
        <h1 style="color: #fff; font-weight: 700">Por que a Divvy?</h1>

        <slider-quotes-anunciar color="#BC7DF0" />
      </div>

      <newsletter-card class="mt-4" />
    </div>

    <Footer />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import Youtube from '@/views/site/components/Youtube.vue'
import StepBanner from '../components/step-banner-2/StepBanner.vue'
import ActionFluxCorretor from '../components/ActionFluxCorretor.vue'
import CardBorderIcon from '../components/CardBorderIcon.vue'
import SliderQuotesAnunciar from '../components/SliderQuotesAnunciar.vue'
import NewsletterCard from '../components/NewsletterCard.vue'
import Footer from '../components/parts/footer/Footer.vue'
import Banner from '../components/Banner.vue'

export default {
  name: 'Corretor',
  components: {
    // ParceirosContrutoras,
    SliderQuotesAnunciar,
    ActionFluxCorretor,
    CardBorderIcon,
    StepBanner,
    BRow,
    BCol,
    Banner,
    NewsletterCard,
    Footer,
    // LinkSeta,
    Youtube,
  },
}
</script>
<style scoped>
.bold {
  font-weight: 600;
}

@media screen and (min-width: 601px) {
  .site-banner {
    padding: 8rem;
  }

  .site-banner-title {
    font-size: 4rem;
  }
}

@media screen and (max-width: 600px) {
  .site-banner {
    padding: 2rem;
  }

  .site-banner-title {
    font-size: 1.5rem;
  }
}

.site-banner {
  height: 60vw;
  color: white;
  max-height: 700px;
  border-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.site-banner-title {
  letter-spacing: 0.1rem;
  margin-bottom: 3rem;
  font-weight: 600;
  color: white;
}

.banner-purple {
  background-repeat: repeat-x;
  background-size: contain;
}

.component-container {
  background-size: contain;
  background-repeat: repeat-x;
}

.component-container {
  padding: 40px;
}

@media screen and (max-width: 600px) {
  .component-container {
    padding: 5vw;
  }
}
</style>
