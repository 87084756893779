<template>
  <b-row>
    <b-col sm="4" class="p-1"><StepItem step="01" title="Faça seu cadastro" text="Preencha as informações do formulário de cadastro. " /></b-col>
    <b-col sm="4" class="p-1"><StepItem step="02" title="Avaliamos seu perfil" text=" Nossa equipe fará a avaliação e liberação do seu acesso a nossa plataforma.  " /></b-col>
    <b-col sm="4" class="p-1"><StepItem step="03" title="Compartilhe e Venda Cotas Imobiliárias!" text="Escolha entre nossas oportunidades de imóveis na planta e inicie suas vendas de cotas imobiliárias." /></b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import StepItem from './StepItem.vue'

export default {
  name: 'Home',

  components: {
    StepItem,
    BRow,
    BCol,
  },
}
</script>
